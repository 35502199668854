import { Scanner } from '@yudiel/react-qr-scanner';
import { useState } from 'react';
import './App.css';
import { sayInput } from './api';
import { ecografe } from './const';

interface ISuccess {
  name: string
  owner: string
}

function App() {
  const [stopDecoding, setStopDecoding] = useState(false);
  const [successMessage, setSuccessMessage] = useState<ISuccess | null>(null);

  const say = (text: string) => {
    sayInput(text, 'Ioana', 0.75, 0.75);
  }

  const decode = (code: string) => {
    if (code.length > 9) {
      const lastTenCharacters = code.slice(-10);
      if (ecografe[lastTenCharacters]) {
        setSuccessMessage(ecografe[lastTenCharacters])
        say(`${ecografe[lastTenCharacters].name}. ${ecografe[lastTenCharacters].owner}`)
      }
      return
    }
    return 'Cod invalid'
  }

  return (
    <div className="App">

      <div style={{
        width: 400,
        margin: 'auto'
      }}>
        <button onClick={() => setStopDecoding((prev) => !prev)} style={{ marginBottom: 5 }}>
          {stopDecoding ? 'Start Decoding' : 'Stop Decoding'}
        </button>
        <Scanner
          onResult={(result: any) => decode(result)}
          onError={(error: any) => console.log(error?.message)}
        />
        <text>{successMessage && successMessage.name}</text>
        <br />
        <text>{successMessage && successMessage.owner}</text>
      </div>
    </div>
  );
}

export default App;
