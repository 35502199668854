export interface IEcografe {
  [key: string]: {
    name: string;
    owner: string;
  };
}

export const ecografe: IEcografe = {
  '5000158104280': {
    name: "Obiect test",
    owner: "Ovidius",
  },
  '0727711220': {
    name: "Ecograf.",
    owner: "Banat",
  },
  'D267285568': {
    name: "Sondă convexă.",
    owner: "Banat",
  },
  'E011691046': {
    name: "Sondă linară.",
    owner: "Banat",
  },
  'D217259830': {
    name: "Sondă phased array.",
    owner: "Banat",
  },

  '0721884584': {
    name: "Ecograf.",
    owner: "Transilvania de Nord",
  },
  'D264701901': {
    name: "Sondă convexă.",
    owner: "Transilvania de Nord",
  },
  'E018417571': {
    name: "Sondă linară.",
    owner: "Transilvania de Nord",
  },
  '2311200043': {
    name: "Carucior ecograf",
    owner: "Transilvania de Sud",
  },
  '0722893643': {
    name: "Ecograf.",
    owner: "Transilvania de Sud",
  },
  'D261424952': {
    name: "Sondă convexă.",
    owner: "Transilvania de Sud",
  },
  'E019533216': {
    name: "Sondă linară.",
    owner: "Transilvania de Sud",
  },
  '0722201529': {
    name: "Ecograf.",
    owner: "Moldova",
  },
  'D263891425': {
    name: "Sondă convexă.",
    owner: "Moldova",
  },
  'E019895218': {
    name: "Sondă linară.",
    owner: "Moldova",
  },
  '23072000555': {
    name: "Carucior ecograf",
    owner: "Muntenia",
  },
  '0728026269': {
    name: "Ecograf.",
    owner: "Muntenia",
  },
  'D263712164': {
    name: "Sondă convexă.",
    owner: "Muntenia",
  },
  'E012184536': {
    name: "Sondă linară.",
    owner: "Muntenia",
  },
  '0728012030': {
    name: "Ecograf.",
    owner: "Oltenia",
  },
  'D265942178': {
    name: "Sondă convexă.",
    owner: "Oltenia",
  },
  'E011610811': {
    name: "Sondă linară.",
    owner: "Oltenia",
  },
  '0721842657': {
    name: "Ecograf.",
    owner: "Uniune",
  },
  '0728960854': {
    name: "Ecograf.",
    owner: "Uniune",
  },
  '0728062595': {
    name: "Ecograf.",
    owner: "Uniune",
  },
  'D269414963': {
    name: "Sondă convexă.",
    owner: "Uniune",
  },
  'D269599284': {
    name: "Sondă convexă.",
    owner: "Uniune",
  },
  'D266621545': {
    name: "Sondă convexă.",
    owner: "Uniune",
  },
  'E014376531': {
    name: "Sondă linară.",
    owner: "Uniune",
  },
  'E019164011': {
    name: "Sondă linară.",
    owner: "Uniune",
  },
  'E019169301': {
    name: "Sondă linară.",
    owner: "Uniune",
  },
  'D214498674': {
    name: "Sondă phased array.",
    owner: "Uniune",
  }

}